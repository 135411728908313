import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Button } from "react-bootstrap";

import SearchBar from '../../SearchBar/SearchBar';
import SearchResultsList from '../../SearchBar/SearchResultsList';

const Qualification = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [qualifications, setQualifications]= useState([]);
    const [organizationName, setOrganizationName] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const organizationUuid = useRef();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    organizationUuid.current = queryParameters.get("organizationUuid");
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid.current, 
                { withCredentials: true });
            let qualificationsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/organization/" + organizationUuid.current, 
                { withCredentials: true });
            setOrganizationName(organizationResponse.data.name);
            setQualifications(qualificationsResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Qualifications</title>
            </Helmet>
            <div className="p-2">
                <h6><a href={"/administer?organizationUuid=" + organizationUuid.current }>{organizationName}</a> {'>'} Qualifications</h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text Margin-Bottom">Qualifications</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Margin-Bottom">
                        <SearchBar 
                            setSearchResults={setSearchResults}
                            apiEndpoint={ "/qualification/organization/" + organizationUuid.current }
                            searchBoxPlaceholder="Type to search the organization's qualifications..." />
                        <SearchResultsList results={searchResults} 
                            urlPrefix={ "/qualification/edit?organizationUuid=" + organizationUuid.current + "&qualificationUuid=" } />
                    </div>
                    <div className="Margin-Bottom">
                        <Button as="a" href={ "/qualification/edit?organizationUuid=" + organizationUuid.current }>Create Qualification</Button>
                    </div>
                    { qualifications.map((qualification) => {
                            return(
                                <div className="list-group" key={qualification.uuid}>
                                    <a href={ "/qualification/edit?organizationUuid=" + organizationUuid.current + "&qualificationUuid=" + qualification.uuid } className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{qualification.name}</h5>
                                            <small>{qualification.type}</small>
                                            </div>
                                        <p className="mb-1">{qualification.description}</p>
                                    </a>
                                </div>
                            )
                    })}
                    { qualifications.length < 1 &&
                        <p className="Center-Text">This organization has no qualifications.</p>
                    } 
                </div> 
            </div> 
        </HelmetProvider>
	);
};

export default Qualification;
