import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ReactCountryDropdown from "react-country-dropdown";
import axios from 'axios';
import TelephoneNumber from "../../TelephoneNumber";

const Profile = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [givenName, setGivenName] = useState("");
    const [surname, setSurname] = useState("");
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [organization, setOrganization] = useState("");
    const [city, setCity] = useState("");
    const [stateProvince, setStateProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("US");
    const [buttonClicked, setButtonClicked] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try
        {
            let userDataResponse = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/details", { withCredentials: true });
            setGivenName(userDataResponse.data.givenName);
            setSurname(userDataResponse.data.surname);
            setOrganization(userDataResponse.data.organization);
            setAddressLine1(userDataResponse.data.addressLine1);
            if (userDataResponse.data.addressLine2)
            {
                setAddressLine2(userDataResponse.data.addressLine2);
            }
            setCity(userDataResponse.data.city);
            setStateProvince(userDataResponse.data.stateProvince);
            setPostalCode(userDataResponse.data.postalCode);
            setTelephoneNumber(userDataResponse.data.telephoneNumber);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleSubmit = async (e) => {
        e.preventDefault();

        setButtonClicked(true);

        const userInfo = {
            givenName: givenName,
            surname: surname,
            organization: organization,
            telephoneNumber: telephoneNumber,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateProvince: stateProvince,
            postalCode: postalCode,
            country: country
        };

        try 
        {
            let response = await axios.put(process.env.REACT_APP_BASE_AUTH_API_URL + '/update', userInfo, { withCredentials: true } );
            setAlertVariant("primary");               
            setMessage(response.data.message);
        }
        catch (error)
        {
            console.log(error)
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setButtonClicked(false);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Profile</title>
            </Helmet>
            <div className="p-3 Center-Outer">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center">
                                    <h3>User Profile</h3>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center Outer">    
                                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center Margin-Bottom">
                                    <Form onSubmit={handleSubmit} className='Form'>
                                        <Form.Group className="mb-3" controlId="formGivenName">
                                            <Form.Label>First Name *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter First Name"
                                            value={givenName}
                                            name="givenName" 
                                            onChange={(e) => setGivenName(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formSurname">
                                            <Form.Label>Last Name *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name"
                                            value={surname}
                                            name="surname" 
                                            onChange={(e) => setSurname(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formOrganization">
                                            <Form.Label>Organization</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Organization"
                                            value={organization}
                                            name="organization" 
                                            onChange={(e) => setOrganization(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAddressLine1">
                                            <Form.Label>Address Line 1 *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address Line 1"
                                            value={addressLine1}
                                            name="addressLine1" 
                                            onChange={(e) => setAddressLine1(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAddressLine2">
                                            <Form.Label>Address Line 2</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address Line 2"
                                                value={addressLine2}
                                                name="addressLine2" 
                                                onChange={(e) => setAddressLine2(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formCity">
                                            <Form.Label>City *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter City"
                                            value={city}
                                            name="city" 
                                            onChange={(e) => setCity(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formStateProvince">
                                            <Form.Label>State/Province *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter State/Province"
                                            value={stateProvince}
                                            name="stateProvince" 
                                            onChange={(e) => setStateProvince(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPostalCode">
                                            <Form.Label>Postal Code *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name"
                                            value={postalCode}
                                            name="postalCode" 
                                            onChange={(e) => setPostalCode(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formCountry">
                                            <Form.Label>Country *</Form.Label><br />
                                            <ReactCountryDropdown
                                            defaultCountry="US"
                                            onSelect={(e) => setCountry(e.name)}
                                            required />
                                        </Form.Group>
                                        <TelephoneNumber 
                                            telephoneNumber={telephoneNumber} 
                                            setTelephoneNumber={setTelephoneNumber} />
                                        <p>
                                            { '* Required' }
                                        </p>
                                        <div className="d-flex flex-wrap">
                                            <div className="p-1">
                                                <Button variant="primary" type="submit" hidden={buttonClicked}>
                                                    Update Profile
                                                </Button>
                                                <Button variant="primary" disabled hidden={!buttonClicked}>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Loading...
                                                </Button>
                                            </div>
                                            <div className="p-1">
                                                <Button as="a" href="/profile/password" style={{marginLeft: "10px"}}>Change Password</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>     
        </HelmetProvider>
	);
};

export default Profile;
