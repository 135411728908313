import React from "react";

import "./AffiliatedUsers.css";

const AffiliatedUsersList = ({setAffiliatedUsersSearchResults,affiliatedUsersSearchResults,setEvaluator,setAffiliatedUsersInput}) => {

    const handleAffiliatedUserSelect = async (user) => {
        setAffiliatedUsersInput(user.surname + ", " + user.givenName + " (" + user.email + ")");
        setAffiliatedUsersSearchResults([]);
        setEvaluator(user);
    }

    if (affiliatedUsersSearchResults.length > 0)
    {
        return (
            <div className="affiliated-users-list">
                {affiliatedUsersSearchResults.map((result) => {
                    return <div className="search-result" key={result.user.email} onClick={() => handleAffiliatedUserSelect(result.user)}>
                                {result.user.surname}, {result.user.givenName} ({result.user.email})
                            </div>
                })}
            </div>
        );
    }
};

export default AffiliatedUsersList;
