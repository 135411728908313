import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./QualificationOptionSelect.css";

const NaicsSelect = ({naicsCodeId, setNaicsCodeId}) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
		fetchData();
	}, []);

    const fetchData = async (value) => {
        try
        {
            let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/qualification/naicsCodes', { withCredentials: true } );
            const typesArray = [];
            result.data.forEach((value) => {
                typesArray.push({
                    key: value.definition,
                    value: value.id,
                });
            });
            setOptions([{key: 'Select NAICS Classification', value: ''}, ...typesArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

	return (
        <Form.Group className="mb-3" controlId="formNaics">
            <Form.Label>NAICS Classification *</Form.Label>
            <Form.Control as="select"
                value={naicsCodeId}
                name="naics" 
                onChange={(e) => setNaicsCodeId(e.target.value)} 
                className="qualification-option-list"
                required>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                        {option.key}
                        </option>
                    );
                })}
            </Form.Control>
        </Form.Group>
	);
};

export default NaicsSelect;
