import { useEffect, useRef } from "react";
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const Logout = () => {

    const authCheckedRef = useRef(false);

    useEffect(() => {
        if (authCheckedRef.current) return;
        authCheckedRef.current = true;
        window.authenticated = false;
        const logout = async () => {
            try 
            {
                await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + '/logout', { withCredentials: true });
            }
            catch (error)
            {
                console.log(error);
                window.authenticated = true;
            }
        }
        logout();
    }, []);

    return <Navigate to="/login" />;
}

export default Logout;