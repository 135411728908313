import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./SelectHolder.css";

import HolderSearchBar from "./HolderSearchBar"; 

const SelectHolder = ({setHolder, setRequirements, qualificationVersionUuid, setQualificationVersionUuid, setValid}) => {

    const [qualificationVersionOptions, setQualificationVersionOptions] = useState([]);

    useEffect(() => {
		fetchData();
	}, []);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
            let qualificationUuid = queryParameters.get("qualificationUuid");
            let versionsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/versions/" + qualificationUuid, 
                { withCredentials: true });
            const versionsArray = [];
            versionsResponse.data.forEach((value) => {
                let key = value.versionName;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                versionsArray.push({
                    key: key,
                    value: value.uuid,
                });
            });
            setQualificationVersionOptions([{key: 'Select Version', value: ''}, ...versionsArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

	return (
        <Form className='Form Margin-Bottom'>
            <Form.Group className="mb-3" controlId="formVersion">
                <Form.Label>Version</Form.Label>
                <Form.Control as="select"
                    value={qualificationVersionUuid}
                    name="qualificationVersion" 
                    onChange={(e) => setQualificationVersionUuid(e.target.value)} 
                    className="qualification-holder-list"
                    required>
                    {qualificationVersionOptions.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        );
                    })}
                </Form.Control>
            </Form.Group>
            { qualificationVersionUuid &&
                <HolderSearchBar
                    qualificationVersionUuid={qualificationVersionUuid} 
                    setHolder={setHolder}
                    setRequirements={setRequirements}
                    setValid={setValid}
                />
            }
        </Form>
	);
};

export default SelectHolder;
