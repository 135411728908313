import { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import AppLayout from './Layout/AppLayout';
import Login from './Pages/Authentication/Login';
import Register from './Pages/Authentication/Register';
import VerifyRegistration from './Pages/Authentication/VerifyRegistration';
import Logout from './Pages/Authentication/Logout'
import Version from './Pages/Version'
import Spinner from 'react-bootstrap/Spinner';
import PasswordResetRequest from './Pages/Authentication/PasswordResetRequest';
import PasswordResetVerify from './Pages/Authentication/PasswordResetVerify';
import Profile from './Pages/Profile';
import Portfolio from './Pages/Portfolio';
import axios from 'axios';
import ResendRegistrationVerificationEmail from './Pages/Authentication/ResendRegistrationVerificationEmail';
import ChangePassword from './Pages/Authentication/ChangePassword';
import Evaluate from './Pages/Evaluate';
import EvaluateQualification from './Pages/Evaluate/EvaluateQualification';
import QualificationStatus from './Pages/Status';
import Administer from './Pages/Administer';
import Evaluators from './Pages/Evaluators';
import Affiliate from './Pages/Affiliate';
import Administrators from './Pages/Administrators';
import OrganizationSearch from './Pages/OrganizationSearch';
import EditOrganization from './Pages/EditOrganization';
import Qualification from './Pages/Qualification';
import EditQualification from './Pages/Qualification/EditQualification';
import QualificationRequirement from './Pages/Qualification/Requirement';
import QualificationVersion from './Pages/Qualification/QualificationVersion';
import Requirement from './Pages/Requirement';
import EditRequirement from './Pages/Requirement/EditRequirement';

window.authenticated = false;

function App() {

  const [isAdmin, setIsAdmin] = useState(false);
  const [isEvaluator, setIsEvaluator] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const authCheckedRef = useRef(false);

  useEffect(() => {
    if (authCheckedRef.current) return;
    authCheckedRef.current = true;
    const checkAuth = async () => {
      try {
        let result = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/verify", { withCredentials: true });
        setIsAdmin(result.data.isAdministrator);
        setIsEvaluator(result.data.isEvaluator);
        setIsSuperUser(result.data.isSuperUser);
        setUserId(result.data.userId);
        window.authenticated = true;
      }
      catch (error) {
        console.log(error);
        window.authenticated = false;
      }
      finally {
        setLoading(false);
      }
    }
    checkAuth();
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center p-3">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  else {
    return (
      <Router>
        <Routes>
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register/verify' element={<VerifyRegistration />} />
          <Route path='/register/resend' element={<ResendRegistrationVerificationEmail />} />
          <Route path='/passwordreset' element={<PasswordResetRequest />} />
          <Route path='/passwordreset/verify' element={<PasswordResetVerify />} />
          <Route path='/version' element={<Version />} />
          <Route path='/logout' element={
            <ProtectedRoute>
              <Logout />
            </ProtectedRoute>
          } />
          <Route path='/' element={
            <ProtectedRoute>
              <AppLayout
                isAdmin={isAdmin}
                isEvaluator={isEvaluator}
                isSuperUser={isSuperUser}
                userId={userId}
              />
            </ProtectedRoute>
          }>
            <Route index element={<Portfolio />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/profile/password' element={<ChangePassword />} />
            <Route path='/affiliate' element={<Affiliate userId={userId} />} />
            <Route path='/evaluate' element={<Evaluate userId={userId} />} />
            <Route path='/evaluate/qualification' element={<EvaluateQualification />} />
            <Route path='/administer' element={<Administer isSuperUser={isSuperUser} />} />
            <Route path='/evaluators' element={<Evaluators />} />
            <Route path='/administrators' element={<Administrators />} />
            <Route path='/qualification/status' element={<QualificationStatus/>} />
            <Route path='/organization/search' element={<OrganizationSearch/>} />
            <Route path='/organization/edit' element={<EditOrganization/>} />
            <Route path='/qualification/list' element={<Qualification/>} />
            <Route path='/qualification/edit' element={<EditQualification />} />
            <Route path='/qualification/requirement' element={<QualificationRequirement />} />
            <Route path='/qualification/version' element={<QualificationVersion />} />
            <Route path='/requirement/list' element={<Requirement/>} />
            <Route path='/requirement/edit' element={<EditRequirement/>} />
          </Route>
        </Routes>
      </Router>
    );
  }
}

export default App;