import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Form } from 'react-bootstrap';

const TelephoneNumber = ({telephoneNumber, setTelephoneNumber}) => {

	return (
    <Form.Group className="mb-3" controlId="formTelephoneNumber">
      <Form.Label>Telephone Number *</Form.Label>
      <PhoneInput
        defaultCountry="US"
        value={telephoneNumber}
        onChange={setTelephoneNumber}
        numberInputProps={{ className: "form-control" }}
        placeholder="Enter Telephone Number"
        required />
    </Form.Group>
	);
};

export default TelephoneNumber;
