import React from "react";
import axios from 'axios';

import "./Holders.css";

const HoldersList = ({setHolderSearchResults, holderSearchResults, setHolderInput, setHolder, setRequirements, qualificationVersionUuid, setValid}) => {

    const handleHolderSelect = async (result) => {
        setHolderInput(result.surname + ", " + result.givenName + " (" + result.email + ")");
        setHolderSearchResults([]);
        setHolder(result);
        try
        {
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/version/holder/" + result.holderUuid, 
                { 
                    params: {
                        qualificationVersionUuid: qualificationVersionUuid
                    },
                    withCredentials: true 
                });
            let sortedResults = results.data.requirements.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
                });
            setRequirements(sortedResults)
            setValid(results.data.valid)
        }
        catch (error)
        {
            console.log(error);
        }
    }

    if (holderSearchResults.length > 0)
    {
        return (
            <div className="results-list">
                {holderSearchResults.map((result) => {
                    return <div className="search-result" key={result.email} onClick={() => handleHolderSelect(result)}>
                                {result.surname}, {result.givenName} ({result.email})
                            </div>
                })}
            </div>
        );
    }
};

export default HoldersList;
