import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./QualificationOptionSelect.css";

const QualificationExpirationTypeSelect = ({qualificationExpirationTypeId, setQualificationExpirationTypeId}) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
		fetchData();
	}, []);

    const fetchData = async (value) => {
        try
        {
            let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/qualification/expirationTypes', { withCredentials: true } );
            const typesArray = [];
            result.data.forEach((value) => {
                typesArray.push({
                    key: value.name + " - " + value.description,
                    value: value.id,
                });
            });
            setOptions([{key: 'Select Expiration Type', value: ''}, ...typesArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

	return (
        <Form.Group className="mb-3" controlId="formExpirationType">
            <Form.Label>Expiration Type *</Form.Label>
            <Form.Control as="select"
                value={qualificationExpirationTypeId}
                name="expirationType" 
                onChange={(e) => setQualificationExpirationTypeId(e.target.value)} 
                className="qualification-option-list"
                required>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                        {option.key}
                        </option>
                    );
                })}
            </Form.Control>
        </Form.Group>
	);
};

export default QualificationExpirationTypeSelect;
