import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import SelectHolder from '../../SelectHolder'

const EvaluateQualification = () => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [qualification, setQualification] = useState("");
    const [organization, setOrganization] = useState("");
    const [showRemoveEvaluatorConfirmation, setShowRemoveEvaluatorConfirmation] = useState(false);
    const [evaluatorRemoved, setEvaluatorRemoved] = useState(false);
    const [holder, setHolder] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [qualificationVersionUuid, setQualificationVersionUuid] = useState("");
    const [valid, setValid] = useState(false);

    const qualificationUuid = useRef("");
    const evaluatorUuid = useRef("");

    const handleCloseRemoveEvaluatorConfirmation = () => setShowRemoveEvaluatorConfirmation(false);
    const handleShowRemoveEvaluatorConfirmation = () => setShowRemoveEvaluatorConfirmation(true);

    function formatDate(timestampString) 
    {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(timestampString).toLocaleDateString(undefined, options)
    }

	useEffect(() => {
        fetchData();
    }, []);

	const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    qualificationUuid.current = queryParameters.get("qualificationUuid");
            evaluatorUuid.current = queryParameters.get("evaluatorUuid");
            let qualificationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/" + qualificationUuid.current, 
                { withCredentials: true, params: { includeRequirements: false } });
            setQualification(qualificationResponse.data);
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + qualificationResponse.data.organizationUuid, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemoveEvaluator = async (e) => {
        e.preventDefault();
        try 
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluator/delete/" + evaluatorUuid.current, 
                { withCredentials: true });
            setMessage(response.data.message);
            setAlertVariant("primary");
            setEvaluatorRemoved(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            handleCloseRemoveEvaluatorConfirmation();
        }
    };

    let handleEndorseRequirement = async (e, requirement) => {
        e.preventDefault();
        try 
        {
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/qualification/requirement/endorse/" + 
                requirement.qualificationVersionRequirementUuid + "/" + holder.holderUuid, 
                {}, { withCredentials: true });
            setMessage(response.data.message);
            setAlertVariant("primary");
            let requirementsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/version/holder/" + holder.holderUuid, 
                { 
                    params: {
                        qualificationVersionUuid: qualificationVersionUuid
                    },
                    withCredentials: true 
                });
            let sortedRequirements = requirementsResponse.data.requirements.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            setRequirements(sortedRequirements);
            setValid(requirementsResponse.data.valid);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
            window.scrollTo(0, 0);
        } 
    };

    if (evaluatorRemoved)
    {
        return (
            <HelmetProvider>
                <Helmet>
                        <title>{ process.env.REACT_APP_APP_NAME } - Endorse</title>
                </Helmet>
                <div className="p-3 Center-Outer">
                    <div className="List Outer">
                        <h3 className="Center-Text">Evaluate Qualification</h3>
                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    </div>
                </div>
            </HelmetProvider>
        )
    }
    else
    {
        return (
            <HelmetProvider>
                <Helmet>
                        <title>{ process.env.REACT_APP_APP_NAME } - Endorse</title>
                </Helmet>
                <div className="p-3 Center-Outer">
                    <div className="List Outer">
                        <h3 className="Center-Text">Evaluate Qualification</h3>
                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                        <div className="Details Margin-Bottom">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Qualification: </td>
                                        <td>{qualification.name}</td>
                                    </tr>
                                    {qualification.description &&
                                        <tr>
                                            <td>Description: </td>
                                            <td>{qualification.description}</td>
                                        </tr>
                                    } 
                                    <tr>
                                        <td>Organization: </td>
                                        <td>{organization.name}</td>
                                    </tr>
                                    { holder &&
                                        <tr>
                                            <td>Status: </td>
                                            <td>{valid ? "Valid" : "Invalid"}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <SelectHolder 
                            holder={holder}
                            setHolder={setHolder}
                            setRequirements={setRequirements}
                            qualificationVersionUuid={qualificationVersionUuid}
                            setQualificationVersionUuid={setQualificationVersionUuid}
                            setValid={setValid}
                        />
                        { holder &&
                            <div className="Details Margin-Bottom">
                                <h5 className="mb-1">{holder.surname}, {holder.givenName}</h5>
                                <div>
                                    Email: {holder.email}
                                </div>
                                { holder.organization &&
                                    <div>
                                        Organization: {holder.organization}
                                    </div>
                                }
                            </div>
                        }
                        { requirements.length > 0 &&
                            <div className="Margin-Bottom">
                                { requirements.map((requirement) => {
                                    return (
                                        <div className="list-group" key={requirement.qualificationVersionRequirementUuid}>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{requirement.name}</h5>
                                                    <small>
                                                        {requirement.requirementType.name}
                                                    </small>
                                                </div>
                                                { requirement.description &&
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p className="mb-1">{requirement.description}</p>
                                                    </div>
                                                }
                                                { requirement.valid && requirement.endorsedBy &&
                                                    <div>
                                                        <p className="mb-1">Endorsed by {requirement.endorsedBy} on {formatDate(requirement.endorsed)}.</p>
                                                    </div>
                                                }
                                                { !requirement.valid && requirement.requirementType.id !== 2 &&
                                                    <div>
                                                        <p className="mb-1">Not Endorsed</p>
                                                    </div>
                                                }
                                                { requirement.valid && requirement.requirementType.id === 2 &&
                                                    <div>
                                                        <p className="mb-1">Valid</p>
                                                    </div>
                                                }
                                                { !requirement.valid && requirement.requirementType.id === 2 &&
                                                    <div>
                                                        <p className="mb-1">Invalid</p>
                                                    </div>
                                                }
                                                { requirement.requirementType.id !== 2 && (!requirement.valid || qualification.qualificationExpirationTypeId === 3) &&
                                                    <div>
                                                        <Button variant="primary" onClick={(e) => handleEndorseRequirement(e, requirement)}>
                                                            Endorse
                                                        </Button><br/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>  
                        }
                        { evaluatorUuid.current &&
                            <div className="Form">
                                Remove yourself as an evaluator for this qualification.<br />
                                <Button variant="danger" onClick={handleShowRemoveEvaluatorConfirmation}>
                                    Remove Evaluator
                                </Button><br/>
                            </div>
                        }
                    </div>
                    <Modal show={showRemoveEvaluatorConfirmation} onHide={handleCloseRemoveEvaluatorConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Evaluator Removal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you certain you want to remove yourself as an evaluator for {qualification.name}?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseRemoveEvaluatorConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleRemoveEvaluator}>
                            Remove Evaluator
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>     
            </HelmetProvider>
        );
    }
}

export default EvaluateQualification;