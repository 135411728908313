import React from "react";

import "./SearchResultsList.css";

const SearchResultsList = ({results, urlPrefix}) => {

	return (
        <div className="results-list">
            {results.map((result) => {
                return <a href={urlPrefix + result.uuid} key={result.uuid} className="results-text">
                        <div className="search-result">
                            {result.name}
                        </div>
                    </a>
            })}
        </div>

	);
};

export default SearchResultsList;
