import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import SelectOrganization from "../../SelectOrganization";

const Affiliate = ({userId}) => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState("");
    const [affiliationToRemove, setAffiliationToRemove] = useState("");
    const [organizationToRemove, setOrganizationToRemove] = useState("");
    const [organizationsInput, setOrganizationsInput] = useState("");
    const [affiliations, setAffiliations] = useState([]);
    const [showAffiliationDeleteConfirmation, setShowDeleteAffiliationConfirmation] = useState(false);
    const [update, setUpdate] = useState(false);
    const handleCloseDeleteAffiliationConfirmation = () => setShowDeleteAffiliationConfirmation(false);
  	const handleShowDeleteAffiliationConfirmation = () => setShowDeleteAffiliationConfirmation(true);

	useEffect(() => {
        const getAffiliations = async () => {
            try
            {
                let response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/user/" + userId,
                    { withCredentials: true });
                setAffiliations(response.data.affiliation);
            }
            catch (error)
            {
                console.log(error)
                setAlertVariant("danger");
                if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                    setMessage(error.response.data.message)
                } else {
                    setMessage("Unknown Error");
                }
            }
        };
        getAffiliations();
        setUpdate(false);
    }, [update, userId]);

    const handleRemoveClick = async (e, organizationToRemove) => {
        
        try
        {
            let response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/" + organizationToRemove.uuid, 
                {
                    params: {
                        userId: userId
                    },
                    withCredentials: true
                });
            setAffiliationToRemove(response.data);
            setOrganizationToRemove(organizationToRemove);
            handleShowDeleteAffiliationConfirmation(e);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
      };

    let handleRemoveAffiliation = async (e) => {
        e.preventDefault();
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/delete/" + affiliationToRemove.uuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
            handleCloseDeleteAffiliationConfirmation();
        }
    };

    let handleCreateAffiliation = async (e) => {
        e.preventDefault();
        try
        {
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/request/" + organization.uuid, 
                {}, { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setOrganization("");
            setOrganizationsInput("");
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Affiliate</title>
			</Helmet>
			<div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Affiliate</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <SelectOrganization 
                        setOrganization={setOrganization}
                        organizationsInput={organizationsInput}
                        setOrganizationsInput={setOrganizationsInput}
                    />
                    { organization &&
                        
                        <div className="Details Margin-Bottom">
                            <h5 className="mb-1">{organization.name}</h5>
                            { organization.description &&
                                <div>
                                    {organization.description}
                                </div> 
                            }
                            <div>
                                Telephone Number: {organization.telephoneNumber}
                            </div>
                            <div>
                                {organization.addressLine1}
                            </div>
                            { organization.addressLine2 &&
                                <div>
                                    {organization.addressLine2}
                                </div>
                            }
                            <div>
                                {organization.city}, {organization.stateProvince}
                            </div>
                            <div>
                                {organization.country}
                            </div>
                            <div className="Margin-Bottom">
                                Affiliation approval is required to affiliate: 
                                {organization.affiliationApprovalRequired ? " Yes" : " No"}
                            </div>
                            <div>
                                <Button variant="primary" onClick={handleCreateAffiliation} type="submit">
                                    {organization.affiliationApprovalRequired ? "Request Affiliation" : "Affiliate"}
                                </Button>
                            </div>
                        </div>
                    }
                    { affiliations.map((affiliation) => {
                        return(
                            <div className="list-group" key={affiliation.uuid + '-affiliation'}>
                                <div className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{affiliation.name}</h5>
                                    </div>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        <p className="mb-1">{affiliation.description}</p>
                                    </div>
                                    <div>
                                    <Button variant="danger" onClick={e => handleRemoveClick(e, affiliation)} type="submit">
                                        Remove Affiliation
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <Modal show={showAffiliationDeleteConfirmation} onHide={handleCloseDeleteAffiliationConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Affiliation Removal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you certain you want to remove the affiliation with {organizationToRemove.name}?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteAffiliationConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={(e) => handleRemoveAffiliation(e)}>
                            Remove Affiliation
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    { affiliations.length < 1 &&
                        <p className="Center-Text">You are not affiliated with any organizations.</p>
                    } 
                </div>
            </div>   
		</HelmetProvider>
	);
}

export default Affiliate;