import { useState } from "react";
import { Nav, Navbar, NavLink, NavDropdown } from "react-bootstrap";
import axios from 'axios';
import { Link } from "react-router-dom";
import Logo from "../Images/SmallLogo.png";

const NavigationBar = ({isEvaluator, isAdmin, userId, isSuperUser}) => {

	const [organizations, setOrganizations] = useState("");
	
	if (isAdmin && !organizations)
	{
		(async () => {
            try
            {
                let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/user/" + userId, 
                    { withCredentials: true });
					let sortedOrganizations = organizationResponse.data.administrator.sort(function (a, b) {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});
                setOrganizations(sortedOrganizations);
            }
            catch (error)
            {
                console.log(error)
            }
            })();
	}

	return (
		<Navbar collapseOnSelect expand="sm" bg="light" data-bs-theme="light">
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse>
				<Nav>
					<Navbar.Brand eventkey={process.env.REACT_APP_APP_NAME + " Home"} 
						href="https://proofpeak.com" style={{marginLeft: '20px'}}>
							 <img
								alt=""
								src={Logo}
								width="30"
								height="30"
								className="d-inline-block align-top"
								/>{' '}
							{process.env.REACT_APP_APP_NAME}</Navbar.Brand>
					<NavLink eventKey="Portfolio" as={Link} to="/">Portfolio</NavLink>
					<NavLink eventKey="Affiliate" as={Link} to="/affiliate">Affiliate</NavLink>
					{ (isEvaluator) &&
						<NavLink eventKey="Evaluate" as={Link} to="/evaluate">Evaluate</NavLink>
					}
					{ (isAdmin || isSuperUser) &&
						<NavDropdown title="Administration" id="basic-nav-dropdown">
							{ isSuperUser &&
								<>
									<NavDropdown.Item href="/organization/edit">Create Organization</NavDropdown.Item>
									<NavDropdown.Item href="/organization/search">Search Organizations</NavDropdown.Item>
								</>
							}
							{ organizations &&
								organizations.map((organization) => {
									return (
										<NavDropdown.Item key={organization.uuid} href={"/administer?organizationUuid=" + organization.uuid}>{organization.name}</NavDropdown.Item>
									);
								})
							}
						</NavDropdown>
					}
					<NavLink eventKey="Profile" as={Link} to="/profile">Profile</NavLink>
					<NavLink eventKey="Logout" as={Link} to="/logout">Logout</NavLink>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default NavigationBar;