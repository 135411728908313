import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import AddQualification from "./AddQualification";
import axios from 'axios';

const Portfolio = () => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
	const [qualifications, setQualifications] = useState([]);
    const [update, setUpdate] = useState(false);

    const userInfo = useRef();

	useEffect(() => {
        fetchData();
        setUpdate(false);
    }, [update]);

	const fetchData = async () => {
        try
        {
            let userDataResponse = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/details", { withCredentials: true });
            userInfo.current = userDataResponse.data;
            let qualificationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/user/" + userInfo.current.userId, 
                { withCredentials: true, params: { includeRequirements: false } });
            setQualifications(qualificationResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Portfolio</title>
			</Helmet>
			<div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Portfolio</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}

                    <h5 className="Margin-Bottom">Valid Qualifications</h5>
                    <div className="Margin-Bottom">
                        { qualifications.map((qualification) => {
                            if (qualification.valid)
                            {
                                return(
                                    <div className="list-group" key={qualification.holderUuid + "-valid"}>
                                        <a href={ "/qualification/status" +
                                            "?organizationUuid=" + qualification.organization.uuid +
                                            "&qualificationVersionUuid=" + qualification.qualificationVersionUuid +
                                            "&holderUuid=" + qualification.holderUuid
                                        }>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{qualification.qualificationName}</h5>
                                                    <small>
                                                        {qualification.versionName}
                                                    </small> 
                                                </div>
                                                { qualification.description &&
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p className="mb-1">{qualification.description}</p>
                                                    </div>
                                                }
                                                <div>
                                                    <p className="mb-1">{qualification.organization.name}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            }
                            else
                            {
                                return null
                            }
                        })}
                    </div>
                    { !qualifications.some(e => Boolean(e.valid) === true) &&
                        <p className="Center-Text">Your portfolio contains no valid qualifications.</p>
                    }  
                    <h5 className="Margin-Bottom">Invalid Qualifications</h5>
                    <div className="Margin-Bottom">
                        { qualifications.map((qualification) => {
                            if (!qualification.valid)
                            {
                                return(
                                    <div className="list-group" key={qualification.holderUuid + "-invalid"}>
                                        <a href={ "/qualification/status" +
                                            "?organizationUuid=" + qualification.organization.uuid +
                                            "&qualificationVersionUuid=" + qualification.qualificationVersionUuid +
                                            "&holderUuid=" + qualification.holderUuid
                                        }>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{qualification.qualificationName}</h5>
                                                    <small>
                                                        {qualification.versionName}
                                                    </small> 
                                                </div>
                                                { qualification.description &&
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p className="mb-1">{qualification.description}</p>
                                                    </div>
                                                }
                                                <div>
                                                    <p className="mb-1">{qualification.organization.name}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            }
                            else
                            {
                                return null
                            }
                        })}
                    </div>
                    { !qualifications.some(e => Boolean(e.valid) === false) &&
                        <p className="Center-Text">Your portfolio contains no invalid qualifications.</p>
                    }
                    <AddQualification 
                        setMessage={setMessage}
                        setAlertVariant={setAlertVariant} 
                        setUpdate={setUpdate} />
                </div>
            </div>     
		</HelmetProvider>
	);
}

export default Portfolio;