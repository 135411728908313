import React, { useState } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./QualificationSearchBar.css";
import OrganizationList from "./OrganizationList";

const QualificationSelect = ({requirementTypeId, qualificationUuid, setQualificationUuid, organizationInput, 
    setOrganizationInput, qualifications, setQualifications}) => {

    const [organizationSearchResults, setOrganizationSearchResults] = useState([]);

    const searchOrgData = async (value) => {
        try
        {
            if (!value)
            {
                return
            }
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/search", 
                { 
                    params: {
                        searchParameter: value
                    },
                    withCredentials: true 
                });

            setOrganizationSearchResults(results.data)
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleOrgChange = (value) => {
        setOrganizationInput(value);
        setQualifications([]);
        setQualificationUuid("");
        if (value === "")
        {
            setOrganizationSearchResults([]);
        }
        else
        {
            searchOrgData(value);
        }
    }

    return (
        <div>
            <Form.Group className="mb-3" controlId="formOrganization">
                <Form.Label>Organization *</Form.Label>
                <Form.Control type="text" placeholder="Type to search organizations..."
                    autoComplete="off"
                    value={organizationInput}
                    name="organization" 
                    onChange={(e) => handleOrgChange(e.target.value)} 
                    required />
                <OrganizationList 
                    organizationSearchResults={organizationSearchResults} 
                    setOrganizationInput={setOrganizationInput} 
                    setOrganizationSearchResults={setOrganizationSearchResults}
                    setQualifications={setQualifications}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formQualification">
                <Form.Label>Qualification *</Form.Label>
                <Form.Control as="select"
                    value={qualificationUuid}
                    name="qualification" 
                    onChange={(e) => setQualificationUuid(e.target.value)} 
                    required>
                    {qualifications.map((option) => {
                        return (
                            <option key={option.uuid} value={option.uuid}>
                                {option.name}
                            </option>
                        );
                    })}
                </Form.Control>
            </Form.Group>
        </div>
    );
};

export default QualificationSelect;
