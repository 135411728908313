import React, { useState } from "react";
import axios from 'axios';
import { FaSearch } from "react-icons/fa";

import "./Organizations.css";
import OrganizationsList from "./OrganizationsList";

const SelectOrganization = ({setOrganization, organizationsInput, setOrganizationsInput}) => {

    const [organizationsSearchResults, setOrganizationsSearchResults] = useState([]);

    const searchOrganizationsData = async (value) => {
        try
        {
            if (!value)
            {
                return;
            }
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/search",
                { 
                    params: {
                        searchParameter: value
                    },
                    withCredentials: true 
                });
            setOrganizationsSearchResults(results.data);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleOrganizationChange = async (value) => {
        setOrganizationsInput(value);
        if (value === "")
        {
            setOrganizationsSearchResults([]);
            setOrganization("");
        }
        else
        {
            searchOrganizationsData(value);
        }
    }

    return (
        <>
            <div className="input-wrapper">
                <FaSearch id="search-icon" />
                <input placeholder="Type to search organizations..."
                    className="input"
                    value={organizationsInput} 
                    onChange={(e) => handleOrganizationChange(e.target.value)} 
                />
            </div>
            <div className="Margin-Bottom">
                <OrganizationsList 
                    setOrganizationsSearchResults={setOrganizationsSearchResults}
                    organizationsSearchResults={organizationsSearchResults}
                    setOrganization={setOrganization}
                    setOrganizationsInput={setOrganizationsInput}
                />
            </div>
        </>
    );
};

export default SelectOrganization;
