import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const DateOfCompletion = ({qualificationExpirationTypeId, expirationDays, setExpirationDays}) => {

    const [showFields, setShowFields] = useState(false);

    useEffect(() => {
        if (Number(qualificationExpirationTypeId) === 1)
        {
            setShowFields(true);
        }
        else
        {
            setShowFields(false);
        }
    }, [qualificationExpirationTypeId]);

    return (
        <div>
            { showFields &&
                <>
                    <Form.Group className="mb-3" controlId="formDays">
                        <Form.Label>Days Until Expiration *</Form.Label>
                        <Form.Control type="number" placeholder="Enter Days Until Expiration"
                            min={1}
                            value={expirationDays}
                            name="days" 
                            onChange={(e) => setExpirationDays(e.target.value)} 
                            required />
                    </Form.Group>
                </>
            }
        </div>
    );
};

export default DateOfCompletion;
