import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button'

import SelectAffiliatedUser from "../../SelectAffiliatedUser";

const Administrators = () => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState("");
    const [administrators, setAdministrators] = useState([]);
    const [user, setUser] = useState("");
    const [affiliatedUsersInput, setAffiliatedUsersInput] = useState("");

	useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	const fetchData = async () => {
        const queryParameters = new URLSearchParams(window.location.search);
        let organizationUuid = queryParameters.get("organizationUuid");
        if (!organization)
        {
            let orgResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid, { withCredentials: true });
            setOrganization(orgResponse.data);
        }
        let administratorsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/administrators/" + organizationUuid, 
            { withCredentials: true });
        setAdministrators(administratorsResponse.data);
    };

    let handleRemoveAdministrator = async (e, administratorUuid) => {
        e.preventDefault();
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/Organization/administrator/delete/" + administratorUuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            fetchData();
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

    let handleCreateAdministrator = async (e) => {
        e.preventDefault();
        try
        {
            let newAdministrator = {
                administratorUserId: user.userId
            };
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/organization/administrator/create/" + organization.uuid, 
                newAdministrator, { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUser("");
            setAffiliatedUsersInput("");
            fetchData();
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Administrators</title>
			</Helmet>
            <div className="p-2">
                <h6><a href={"/administer?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '}
                Administrators</h6>
            </div>
			<div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Administrators</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <SelectAffiliatedUser 
                        setUser={setUser}
                        organization={organization}
                        affiliatedUsersInput={affiliatedUsersInput}
                        setAffiliatedUsersInput={setAffiliatedUsersInput}
                    />
                    { user &&
                        <div className="Details Margin-Bottom">
                            <h5 className="mb-1">{user.surname}, {user.givenName}</h5>
                            <div>
                                Email: {user.email}
                            </div>
                            <div>
                                Telephone: {user.telephoneNumber}
                            </div>
                            <div>
                                Organization: {user.organization}
                            </div>
                            <div>
                                {user.addressLine1}
                            </div>
                            { user.addressLine2 &&
                                <div>
                                    {user.addressLine2}
                                </div>
                            }
                            <div>
                                {user.city}, {user.stateProvince} {user.postalCode}
                            </div>
                            <div className="Margin-Bottom">
                                {user.country}
                            </div>
                            <Button variant="primary" onClick={(e) => handleCreateAdministrator(e)} type="submit">
                                Add Administrator
                            </Button>
                        </div>
                    }
                    { administrators.map((administrator) => {
                        return(
                            <div className="list-group" key={administrator.email}>
                                <div className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{administrator.surname}, {administrator.givenName}</h5>
                                    </div>
                                    <div>
                                        Email: {administrator.email}
                                    </div>
                                    <div>
                                        Telephone: {administrator.telephoneNumber}
                                    </div>
                                    <div>
                                        Organization: {administrator.organization}
                                    </div>
                                    <div>
                                        {administrator.addressLine1}
                                    </div>
                                    { administrator.addressLine2 &&
                                        <div>
                                            {administrator.addressLine2}
                                        </div>
                                    }
                                    <div>
                                        {administrator.city}, {administrator.stateProvince} {administrator.postalCode}
                                    </div>
                                    <div>
                                        {administrator.country}
                                    </div>
                                    <Button variant="danger" onClick={(e) => handleRemoveAdministrator(e, administrator.administratorUuid)} type="submit">
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                    { administrators.length < 1 &&
                        <p className="Center-Text">This organization has no administrators.</p>
                    } 
                </div>
            </div>   
		</HelmetProvider>
	);
}

export default Administrators;