import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button'

import SelectAffiliatedUser from "../../SelectAffiliatedUser";

function formatDate(timestampString) 
{
    const options = { year: "numeric", month: "long", day: "numeric"}
    return new Date(timestampString).toLocaleDateString(undefined, options)
}

const Evaluators = () => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState("");
    const [user, setUser] = useState("");
    const [qualificationOptions, setQualificationOptions] = useState([]);
    const [qualificationUuid, setQualificationUuid] = useState("");
    const [evaluators, setEvaluators] = useState([]);
    const [affiliatedUsersInput, setAffiliatedUsersInput] = useState("");

	useEffect(() => {
        fetchData();
    }, []);

	const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
            let organizationUuid = queryParameters.get("organizationUuid");
            let orgResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid, { withCredentials: true });
            setOrganization(orgResponse.data);
            let qualificationsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/organization/" + organizationUuid, 
                { withCredentials: true });
            
            let qualificationsArray = [];
            qualificationsResponse.data.forEach((value) => {
                let key = value.name;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                qualificationsArray.push({
                    key: key,
                    value: value.uuid,
                });
            });
            let sortedQualifications = qualificationsArray.sort(function (a, b) {
                if (a.key < b.key) {
                    return -1;
                }
                if (a.key > b.key) {
                    return 1;
                }
                return 0;
            });
            sortedQualifications = [{key: 'Select Qualification', value: ''}, ...sortedQualifications]
            setQualificationOptions(sortedQualifications);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let getEvaluators = async (qualUuid) => {
        try
        {
            let evaluatorsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluators/" + qualUuid, 
                { withCredentials: true });
            setEvaluators(evaluatorsResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleQualificationSelect = async (qualUuid) => {
        try
        {
            if (!qualUuid)
            {
                setUser("");
                setQualificationUuid("");
                setEvaluators([]);
                return;
            }
            getEvaluators(qualUuid);
            setQualificationUuid(qualUuid);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemoveEvaluator = async (e, evaluatorUuid) => {
        e.preventDefault();
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluator/delete/" + evaluatorUuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            getEvaluators(qualificationUuid);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

    let handleCreateEvaluator = async (e) => {
        e.preventDefault();
        try
        {
            let newEvaluator = {
                userId: user.userId
            };
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluator/create/" + qualificationUuid, 
                newEvaluator, { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUser("");
            setAffiliatedUsersInput("");
            getEvaluators(qualificationUuid);
            
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Evaluators</title>
			</Helmet>
            <div className="p-2">
                <h6><a href={"/administer?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '}
                Evaluators</h6>
            </div>
			<div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Evaluators</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <Form.Group className="mb-3" controlId="formVersion">
                        <Form.Label>Qualification</Form.Label>
                        <Form.Control as="select"
                            value={qualificationUuid}
                            name="qualification" 
                            onChange={(e) => handleQualificationSelect(e.target.value)} 
                            className="qualification-version-list"
                            required>
                            {qualificationOptions &&
                                qualificationOptions.map((option) => {
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    );
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                    { qualificationUuid &&
                        <SelectAffiliatedUser 
                            setUser={setUser}
                            organization={organization}
                            setQualificationOptions={setQualificationOptions}
                            affiliatedUsersInput={affiliatedUsersInput}
                            setAffiliatedUsersInput={setAffiliatedUsersInput}
                        />
                    }
                    { user &&
                        <div className="Details Margin-Bottom">
                            <h5 className="mb-1">{user.surname}, {user.givenName}</h5>
                            <div>
                                Email: {user.email}
                            </div>
                            <div>
                                Telephone: {user.telephoneNumber}
                            </div>
                            <div>
                                Organization: {user.organization}
                            </div>
                            <div>
                                {user.addressLine1}
                            </div>
                            { user.addressLine2 &&
                                <div>
                                    {user.addressLine2}
                                </div>
                            }
                            <div>
                                {user.city}, {user.stateProvince} {user.postalCode}
                            </div>
                            <div className="Margin-Bottom">
                                {user.country}
                            </div>
                            <Button variant="primary" onClick={(e) => handleCreateEvaluator(e)} type="submit">
                                Add Evaluator
                            </Button>
                        </div>
                    }
                    { evaluators.map((evaluator) => {
                        return(
                            <div className="list-group" key={evaluator.email}>
                                <div className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{evaluator.surname}, {evaluator.givenName}</h5>
                                        <small>
                                            Evaluator Since: {formatDate(evaluator.created)} 
                                        </small> 
                                    </div>
                                    <div>
                                        Email: {evaluator.email}
                                    </div>
                                    <div>
                                        Telephone: {evaluator.telephoneNumber}
                                    </div>
                                    <div>
                                        Organization: {evaluator.organization}
                                    </div>
                                    <div>
                                        {evaluator.addressLine1}
                                    </div>
                                    { evaluator.addressLine2 &&
                                        <div>
                                            {evaluator.addressLine2}
                                        </div>
                                    }
                                    <div>
                                        {evaluator.city}, {evaluator.stateProvince} {evaluator.postalCode}
                                    </div>
                                    <div>
                                        {evaluator.country}
                                    </div>
                                    <Button variant="danger" onClick={(e) => handleRemoveEvaluator(e, evaluator.uuid, false)} type="submit">
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                    { qualificationUuid && evaluators.length < 1 &&
                        <p className="Center-Text">This qualification has no evaluators.</p>
                    } 
                </div>
            </div>   
		</HelmetProvider>
	);
}

export default Evaluators;