import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const FooterLinks = () => {
    return (
        <>
            <a href="/login" className="link-primary">Login</a>
            {"\u00A0|\u00A0"}
            <a href="/register" className="link-primary">Register</a>
        </>
    );
}

const PasswordResetVerify = () => {

    const queryParameters = new URLSearchParams(window.location.search);
    const emailParameter = queryParameters.get("email");
    const tokenParameter = queryParameters.get("token");

    const [email, setEmail] = useState(emailParameter);
	const [token, setToken] = useState(tokenParameter);
    const [message, setMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
    const [alertVariant, setAlertVariant] = useState("danger");
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const HandleRedirect = () => {
        return setTimeout(() => setRedirectToLogin(true), 5000);
    }

	let handleSubmit = async (e) => {
        e.preventDefault();

        if (password1 !== password2)
        {
            setMessage("The passwords do not match.");
            return;
        }

        const passwordReset = {
            email: email,
            password1: password1,
            password2: password2,
            token: token
        };

        try 
        {
            setButtonClicked(true);
            let response = await axios.post(process.env.REACT_APP_BASE_AUTH_API_URL + '/resetPassword', passwordReset );
            setAlertVariant("primary");               
            setMessage(response.data.message + " Redirecting to login page...");
            setEmail("");
            setPassword1("");
            setPassword2("");
            setToken("");
            HandleRedirect(true);
        }
        catch (error)
        {
            console.log(error)
            
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setButtonClicked(false);
        }
    };

    if (redirectToLogin)
    {
        return <Navigate to="/login" />;
    }

	return (
        <HelmetProvider>
            <Helmet>
                <title>{ process.env.REACT_APP_APP_NAME } - Password Reset</title>
            </Helmet>
            <div className="d-flex align-items-center justify-content-center p-3">
                <table>
                    <tbody>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                <h3>Password Reset</h3>
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center Outer">    
                                {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                <Form onSubmit={handleSubmit} className='Form'>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Username (email address)</Form.Label>
                                        <Form.Control type="email" placeholder="Enter Username"
                                        value={email}
                                        name="email" 
                                        onChange={(e) => setEmail(e.target.value)} 
                                        required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Control type="hidden" placeholder="Enter Verification Code"
                                        value={token}
                                        name="token" 
                                        required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPassword1">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter Password"
                                        value={password1}
                                        name="password1" 
                                        onChange={(e) => setPassword1(e.target.value)} 
                                        required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPassword2">
                                        <Form.Label>Re-Enter Password</Form.Label>
                                        <Form.Control type="password" placeholder="Re-Enter Password"
                                        value={password2}
                                        name="password2" 
                                        onChange={(e) => setPassword2(e.target.value)} 
                                        required />
                                    </Form.Group>
                                    <p>
                                        { 'All fields are required.' }
                                    </p>
                                    <Button variant="primary" type="submit" hidden={buttonClicked}>
                                        Reset Password
                                    </Button>
                                    <Button variant="primary" disabled hidden={!buttonClicked}>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>
                                </Form>
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                { FooterLinks() }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </HelmetProvider>
	);
}

export default PasswordResetVerify;