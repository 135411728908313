import React from "react";

import "./Organizations.css";

const OrganizationsList = ({setOrganizationsSearchResults,organizationsSearchResults,setOrganization,setOrganizationsInput}) => {

    const handleOrganizationSelect = async (org) => {
        setOrganizationsInput(org.name + " - " + org.description);
        setOrganizationsSearchResults([]);
        setOrganization(org);
    }

    if (organizationsSearchResults.length > 0)
    {
        return (
            <div className="affiliated-users-list">
                {organizationsSearchResults.map((result) => {
                    return <div className="search-result" key={result.name + " - " + result.description} onClick={() => handleOrganizationSelect(result)}>
                                {result.name} - {result.description}
                            </div>
                })}
            </div>
        );
    }
};

export default OrganizationsList;
