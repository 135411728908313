import React, { useState } from "react";
import axios from 'axios';
import { FaSearch } from "react-icons/fa";

import "./SearchBar.css"

const SearchBar = ({setSearchResults, apiEndpoint, searchBoxPlaceholder}) => {
    const [input, setInput] = useState("");

    const fetchData = async (value) => {
        try
        {
            if (!value)
            {
                return
            }
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + apiEndpoint, 
                { 
                    params: {
                        searchParameter: value
                    },
                    withCredentials: true 
                });

            setSearchResults(results.data)
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleChange = (value) => {
        setInput(value);
        if (value === "")
        {
            setSearchResults([]);
        }
        else
        {
            fetchData(value);
        }
    }

	return (
        <div className="input-wrapper">
            <FaSearch id="search-icon" />
            <input placeholder={searchBoxPlaceholder}
                className="input"
                value={input} 
                onChange={(e) => handleChange(e.target.value)}/>
        </div>

	);
};

export default SearchBar;
