import { Outlet } from "react-router-dom";
import NavigationBar from "../NavigationBar";

const AppLayout = ({isEvaluator, isAdmin, isSuperUser, userId}) => {
    return <div>
            <NavigationBar 
                isEvaluator={isEvaluator}
                isAdmin={isAdmin}
                isSuperUser={isSuperUser}
                userId={userId}
            />
            <Outlet />
        </div>;
};

export default AppLayout;
