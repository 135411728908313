import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const Evaluate = ({userId}) => {  
	
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("danger");
	const [qualifications, setQualifications] = useState("");

	useEffect(() => {
        (async () => {
            try
            {
                let qualificationEvaluatorResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/user/evaluator/" + userId, 
                    { withCredentials: true });
                setQualifications(qualificationEvaluatorResponse.data);
            }
            catch (error)
            {
                console.log(error)
                setAlertVariant("danger");
                if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                    setMessage(error.response.data.message)
                } else {
                    setMessage("Unknown Error");
                }
            }
            })();
    }, [userId]);

    return (
        <HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Qualification Evaluator</title>
            </Helmet>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Qualification Evaluator</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}                    
                    { qualifications && qualifications.length > 0 &&
                        <div className="Margin-Bottom">
                            { qualifications.map((qualification) => {
                                return (
                                    <div className="list-group" key={qualification.evaluatorUuid}>
                                        <a href={
                                            "/evaluate/qualification" +
                                            "?qualificationUuid=" + qualification.qualificationUuid +
                                            "&evaluatorUuid=" + qualification.evaluatorUuid
                                        }>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{qualification.name}</h5>
                                                    <small>
                                                        {qualification.type}
                                                    </small>
                                                </div>
                                                { qualification.description &&
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p className="mb-1">{qualification.description}</p>
                                                    </div>
                                                }
                                                <div>
                                                    <small><i>{qualification.organizationName}</i></small>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>  
                    }
                    { (!qualifications || qualifications.length < 1) &&
                        <div className="Center-Text">
                            You are not an evaluator for any qualifications.
                        </div>
                    }
                </div>
            </div>     
        </HelmetProvider>
    );
}

export default Evaluate;