import React, { useState } from "react";
import axios from 'axios';
import { FaSearch } from "react-icons/fa";

import "./AffiliatedUsers.css";
import AffiliatedUsersList from "./AffiliatedUsersList";

const SelectAffiliatedUser = ({setUser, organization, affiliatedUsersInput, setAffiliatedUsersInput}) => {

    const [affiliatedUsersSearchResults, setAffiliatedUsersSearchResults] = useState([]);
    

    const searchAffiliatedUsersData = async (value) => {
        try
        {
            if (!value)
            {
                return;
            }
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliations/active/" 
                    + organization.uuid, 
                { 
                    params: {
                        searchParameter: value
                    },
                    withCredentials: true 
                });
            setAffiliatedUsersSearchResults(results.data);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleAffiliatedUserChange = async (value) => {
        setAffiliatedUsersInput(value);
        if (value === "")
        {
            setAffiliatedUsersSearchResults([]);
            setUser("");
        }
        else
        {
            searchAffiliatedUsersData(value);
        }
    }

    return (
        <>
            <div className="input-wrapper">
                <FaSearch id="search-icon" />
                <input placeholder="Type to search affiliated users..."
                    className="input"
                    value={affiliatedUsersInput} 
                    onChange={(e) => handleAffiliatedUserChange(e.target.value)} 
                />
            </div>
            <div className="Margin-Bottom">
                <AffiliatedUsersList 
                    setAffiliatedUsersSearchResults={setAffiliatedUsersSearchResults}
                    affiliatedUsersSearchResults={affiliatedUsersSearchResults}
                    setEvaluator={setUser}
                    setAffiliatedUsersInput={setAffiliatedUsersInput}
                />
            </div>
        </>
    );
};

export default SelectAffiliatedUser;
