import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const ChangePassword = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);

    let handleSubmit = async (e) => {
        e.preventDefault();

        setButtonClicked(true);

        const passwordInfo = {
            currentPassword: currentPassword,
            password1: password1,
            password2: password2
        };

        try 
        {
            if (password1 !== password2)
            {
                setMessage("The passwords are not the same.");
                return;
            }
            let response = await axios.post(process.env.REACT_APP_BASE_AUTH_API_URL + '/changePassword', passwordInfo, { withCredentials: true } );
            setAlertVariant("primary");               
            setMessage(response.data.message);
            setCurrentPassword("");
            setPassword1("");
            setPassword2("");
        }
        catch (error)
        {
            console.log(error)
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setButtonClicked(false);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Change Password</title>
            </Helmet>
            <div className="p-3 Center-Outer">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center">
                                    <h3>Change Password</h3>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center Outer">    
                                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center Margin-Bottom">
                                    <Form onSubmit={handleSubmit} className='Form'>
                                        <Form.Group className="mb-3" controlId="formCurrentPassword">
                                            <Form.Label>Current Password *</Form.Label>
                                            <Form.Control type="password" placeholder="Enter Current Password"
                                            value={currentPassword}
                                            name="currentPassword" 
                                            onChange={(e) => setCurrentPassword(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPassword1">
                                            <Form.Label>New Password *</Form.Label>
                                            <Form.Control type="password" placeholder="Enter New Password"
                                            value={password1}
                                            name="password1" 
                                            onChange={(e) => setPassword1(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPassword2">
                                            <Form.Label>Re-Enter New Password *</Form.Label>
                                            <Form.Control type="password" placeholder="Re-Enter New Password"
                                            value={password2}
                                            name="password2" 
                                            onChange={(e) => setPassword2(e.target.value)} 
                                            required />
                                        </Form.Group>
                                        <p>
                                            { '* Required' }
                                        </p>
                                        <Button variant="primary" type="submit" hidden={buttonClicked}>
                                            Change Password
                                        </Button>
                                        <Button variant="primary" disabled hidden={!buttonClicked}>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>
                                    </Form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>     
        </HelmetProvider>
	);
};

export default ChangePassword;
