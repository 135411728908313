import React from "react";
import axios from 'axios';

import "./QualificationSearchBar.css";

const OrganizationList = ({organizationSearchResults, setOrganizationSearchResults, setOrganizationInput, setQualifications}) => {

    const handleOrgSelect = async (result) => {
        setOrganizationInput(result.name);
        setOrganizationSearchResults([]);
        try
        {
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/organization/" + result.uuid, 
                { 
                    withCredentials: true 
                });
    
            setQualifications([{name: 'Select Qualification', uuid: ''}, ...results.data]);
        }
        catch (error)
        {
            console.log(error);
        }
    }

    if (organizationSearchResults.length > 0)
    {
        return (
            <div className="results-list">
                {organizationSearchResults.map((result) => {
                    return <div className="search-result" key={result.uuid} onClick={() => handleOrgSelect(result)}>
                                {result.name}
                            </div>
                })}
            </div>
        );
    }
};

export default OrganizationList;
