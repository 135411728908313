import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./AddQualification.css";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';

const AddQualification = ({setMessage, setAlertVariant, setUpdate}) => {

    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [organizationUuid, setOrganizationUuid] = useState("");
    const [qualificationOptions, setQualificationOptions] = useState([]);
    const [qualificationUuid, setQualificationUuid] = useState("");
    const [qualificationVersionUuid, setQualificationVersionUuid] = useState("");
    const [qualificationVersionOptions, setQualificationVersionOptions] = useState([]);
    const [addButtonClicked, setAddButtonClicked] = useState(false);

    useEffect(() => {
		fetchData();
	}, []);

    const fetchData = async () => {
        try
        {
            let userDataResponse = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/details", { withCredentials: true });
            let organizationsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/user/" + userDataResponse.data.userId, { withCredentials: true });
            const organizationsArray = [];
            organizationsResponse.data.affiliation.forEach((value) => {
                let key = value.name;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                organizationsArray.push({
                    key: key,
                    value: value.uuid,
                });
            });
            setOrganizationOptions([{key: 'Select Organization', value: ''}, ...organizationsArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleOrgChange = async (orgUuid) => {
        try
        {
            setOrganizationUuid(orgUuid);
            let qualificationsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/organization/" + orgUuid, 
                { withCredentials: true });
            const qualificationsArray = [];
            qualificationsResponse.data.forEach((value) => {
                let key = value.name;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                qualificationsArray.push({
                    key: key,
                    value: value.uuid,
                });
            });
            setQualificationOptions([{key: 'Select Qualification', value: ''}, ...qualificationsArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleQualificationChange = async (qualUuid) => {
        try
        {
            setQualificationUuid(qualUuid);
            let versionsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/versions/" + qualUuid, 
                { withCredentials: true });
            const versionsArray = [];
            versionsResponse.data.forEach((value) => {
                let key = value.versionName;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                versionsArray.push({
                    key: key,
                    value: value.uuid,
                });
            });
            setQualificationVersionOptions([{key: 'Select Qualification Version', value: ''}, ...versionsArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    let handleAdd = async (e) => {
        e.preventDefault();
        try 
        {
            setAddButtonClicked(true);
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/qualification/holder/create/" + qualificationVersionUuid, 
                {}, { withCredentials: true });
            setMessage(response.data.message);
            setAlertVariant("primary");
            setUpdate(true);  
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setAddButtonClicked(false);
        }
    };

	return (
        <div>
            <h5>Add Qualification</h5>
            <Form onSubmit={handleAdd} className='Form Margin-Bottom'>
                <Form.Group className="mb-3" controlId="formOrganization">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control as="select"
                        value={organizationUuid}
                        name="organization" 
                        onChange={(e) => handleOrgChange(e.target.value)} 
                        className="qualification-version-list"
                        required>
                        {organizationOptions.map((option) => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.key}
                                </option>
                            );
                        })}
                    </Form.Control>
                </Form.Group>
                { organizationUuid &&
                    <>
                        <Form.Group className="mb-3" controlId="formQualification">
                            <Form.Label>Qualification</Form.Label>
                            <Form.Control as="select"
                                value={qualificationUuid}
                                name="qualification" 
                                onChange={(e) => handleQualificationChange(e.target.value)} 
                                className="qualification-version-list"
                                required>
                                {qualificationOptions.map((option) => {
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        {qualificationUuid &&
                            <Form.Group className="mb-3" controlId="formVersion">
                                <Form.Label>Version</Form.Label>
                                <Form.Control as="select"
                                    value={qualificationVersionUuid}
                                    name="qualificationVersion" 
                                    onChange={(e) => setQualificationVersionUuid(e.target.value)} 
                                    className="qualification-version-list"
                                    required>
                                    {qualificationVersionOptions.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </Form.Group>
                        }
                        {qualificationVersionUuid &&
                            <>
                                <Button variant="primary" type="submit" hidden={addButtonClicked}>
                                    Add Qualification To Portfolio
                                </Button>
                                <Button variant="primary" disabled hidden={!addButtonClicked}>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                            </>
                        }
                    </>
                }
            </Form>
        </div>
	);
};

export default AddQualification;
